import { Fragment } from 'react';
import { Doughnut, HorizontalBar,Line } from 'react-chartjs-2';
import { useTheme } from '@emotion/react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';

import { addPaddingPlugin } from '../../../components/plugins/ChartPlugins';
import { DPIAwareCanvas } from '../../../components/ui/MCanvas';
import { colors } from '../../../styles/colors';
import { average, kernelDensityEstimation, linspace, multiplyArrays, stdDev } from '../../../utils/mathUtils';


const SIDE_MAP = {
  left: 'L',
  right: 'R'
}

export const MHRSetDoughnut = ({ sets }) => {
  const setTypeCount = {
    left: 0,
    right: 0,
    dual: 0,
    alternate: 0,
  }


  for (const set of sets) {
    setTypeCount[set.stype] += 1
  }

  const data = {
    labels: Object.keys(setTypeCount),
    datasets: [{
      data: Object.values(setTypeCount),
      backgroundColor: [
        colors.darkBlue,
        colors.lightBlue,
        colors.lightGrey,
        colors.darkGrey
      ],
      borderWidth: 1,
    }]
  }

  const options = {     
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 70
  };
 
  return (
    <Box sx={{ display: 'flex', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
        <Doughnut data={data} options={options}/>
        <Box sx={{position: 'absolute'}}>
          {Object.values(setTypeCount).reduce((bin, count) => (bin + count), 0)}
        </Box>
    </Box>
  )
}

MHRSetDoughnut.propTypes = {
  sets: PropTypes.array,
};


export const MHRRepetitionCountDoughnut = ({sets}) => {
  const theme = useTheme();
  
  const repetitionCount = {
    left: 0,
    right: 0,
  }

  for (const set of sets) {
    for (const side in repetitionCount) {
      const count = set.raises[SIDE_MAP[side]]?.length

      repetitionCount[side] += count ? count : 0
    }
  }

  const data = {
    labels: Object.keys(repetitionCount),//&.map(stype => stype[0].capitalize() + stype.slice(1)),
    datasets: [{
      data: Object.values(repetitionCount),
      backgroundColor: [
        theme.palette.info.main,
        theme.palette.info.light,
      ],
      borderWidth: 1,
    }]
  }

  const options = {     
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 70,
    rotation: -Math.PI
  };
 
  return (
    <Box sx={{ display: 'flex', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
        <Doughnut data={data} options={options}/>
        <Box sx={{display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'absolute'}}>
          <Box>
            <Typography>
              {Object.values(repetitionCount).reduce((bin, count) => (bin + count), 0)}
            </Typography>
          </Box>
          <Box>
            <Typography sx={{fontSize: 13}}>
              {Object.values(repetitionCount).join(" | ")}
            </Typography>
          </Box>
        </Box>
    </Box>
  )
}

MHRRepetitionCountDoughnut.propTypes = {
  sets: PropTypes.array,
};


export const MHRHeelRaisePictogram = ({values, color}) => {
  const mean = average(values)
  const std = stdDev(values)


  const TRACES = [5, 15, 25, 35, 45, 55]

  const kdeBounds = 20
  const maxAngle = Math.min(TRACES[TRACES.length - 1], mean + kdeBounds)
  const minAngle = Math.max(TRACES[0], mean - kdeBounds)

  const angles = linspace(minAngle, maxAngle, 100)
  const hist = kernelDensityEstimation(values, angles, 1.5)
  
  const shoeImage = new Image()
  shoeImage.src = process.env.PUBLIC_URL + `/simplified-shoe.jpg`

  const draw = (ctx) => {
    const width = ctx.canvas.offsetWidth
    const height = ctx.canvas.offsetHeight
    
    shoeImage.onload = function() {
      draw(ctx)
    }

    if (shoeImage.complete) {
      const paddingLeft = 5
      const paddingBottom = 3
      
      const textPadding = 12
      
      const innerWidth = width - paddingLeft
      
      const arcRadii = [0.6 * innerWidth, innerWidth - textPadding]
      
      const ar = shoeImage.width / shoeImage.height;
      const relImageWidth = arcRadii[0] - textPadding;
      const relIimageHeight = 1 / ar * relImageWidth;

      
      
      ctx.translate(paddingLeft, height - paddingBottom)
      ctx.save()
      
      // Indicate ground
      ctx.beginPath()
      ctx.moveTo(0, 0)
      ctx.lineTo(arcRadii[1], 0)
      ctx.strokeStyle = "gray"
      ctx.lineWidth = 2
      ctx.stroke()
      ctx.closePath()
      
      // Draw shoe image
      ctx.rotate(-mean/180 * Math.PI)
      ctx.drawImage(shoeImage, 0, -relIimageHeight, relImageWidth, relIimageHeight);

      ctx.beginPath();
      ctx.setLineDash([5, 5]);
      ctx.moveTo( paddingLeft , 0);
      ctx.lineTo(arcRadii[1], 0)
      ctx.strokeStyle = color;
      ctx.lineWidth = 2;
      ctx.lineCap = 'round';
      ctx.stroke()
      ctx.restore()
      
      // Plot Trace lines on arc
      for (const trace of TRACES) {
        ctx.beginPath();
        ctx.rotate(-trace/180 * Math.PI)
        ctx.moveTo(arcRadii[0], 0);
        ctx.lineTo(arcRadii[1], 0)
        ctx.strokeStyle = 'rgb(0 0 0 / 20%)';
        ctx.lineWidth = 1;
        ctx.lineCap = 'round';
        ctx.stroke()


        ctx.fillStyle = 'rgb(0 0 0 / 50%)';
        ctx.font = "11px sans-serif";

        ctx.fillText(trace + "°", arcRadii[1], 3);

        ctx.rotate(trace / 180 * Math.PI)
      }

      // Position the histogram
      const radialScaling = 0.6 * (arcRadii[1] - arcRadii[0]) / Math.max(...hist);
        
      ctx.beginPath();
      const x0 = arcRadii[0] * Math.cos(angles[0]/180 * Math.PI)
      const y0 = -arcRadii[0] * Math.sin(angles[0]/180 * Math.PI)
      ctx.moveTo(
        x0, y0
      ) 
      
      let x
      let y
      for (let idx = 0; idx < hist.length; idx++) {
        x = (hist[idx] * radialScaling + arcRadii[0]) * Math.cos(angles[idx]/180 * Math.PI);
        y = -(hist[idx] * radialScaling + arcRadii[0]) * Math.sin(angles[idx]/180 * Math.PI);

        ctx.lineTo(x, y)
      }
      ctx.strokeStyle = color;
      ctx.lineWidth = 2;
      ctx.lineCap = 'round';
      ctx.stroke()
      
      ctx.arc(0, 0, arcRadii[0], -(mean + kdeBounds)/180 * Math.PI, -(mean - kdeBounds)/180 * Math.PI)
      ctx.fillStyle = `${color}30`;
      ctx.closePath()
      ctx.fill()
    }
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', height: '100%', width: '100%', alignItems: 'center', paddingTop: 10}}>
      <Typography variant="caption">
        {`${mean.toFixed(2)} ±  ${std.toFixed(2)}°`}
      </Typography>
      <div style={{ height: 'calc(100% - 20px)', width: '100%' }}>
        <DPIAwareCanvas draw={draw} aspectRatio={1.2}/>
      </div>
    </div>
  );
};

MHRHeelRaisePictogram.propTypes = {
  values: PropTypes.array,
  color: PropTypes.string,
};


export const MHRDurationHist = ({values, color}) => {
  const mean = average(values)
  const std = stdDev(values, mean, values.length)
  
  const samples = linspace(0, 3, 301)

  const distribution = kernelDensityEstimation(values, samples, 0.1)

  const minimized = window.innerHeight < 900

  const chartData = {
    labels: samples,
    datasets: [{
      fill: true,
      borderColor: color,
      backgroundColor: `${color}40`,
      borderJoinStyle: 'round',
      lineTension: 0.2,
      pointBorderWidth: 1,
      pointRadius: 0,
      borderWidth: 0.75,
      data: distribution,
    }]
  };

  const options = {
    responsive:true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        scaleLabel: {
          display: !minimized,
          labelString: 'Heel-Raise Duration [s]'
        },
        ticks: {
          callback: function(tickValue) {
            if (tickValue % 0.5 === 0) {
              return tickValue;
            }
          },
          autoSkip: false,
        },
        gridLines: {
          display: true,
          
        }
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Occurence'
        },
        ticks: {
          display: false,
        },
        gridLines: {
          display: false
        }
      }]
    },
    tooltips: {enabled: false},
    hover: {mode: null}
  } 
  // return a component containing a line chart ensure that the cart is contained within a div and does not exceed it.  
  
return (
  <div style={{display: 'flex', flexDirection: 'column', height: '100%', width: '100%', alignItems: 'center', paddingTop: 10}}>
    <Typography variant="caption">
        {`${mean.toFixed(2)} ±  ${std.toFixed(2)}s`}
      </Typography>
      <div style={{ display: 'flex', height: 'calc(100% - 20px)', width: '100%' }}>
        <Line data={chartData} options={options} style={{height: '100%', width: '100%' }} />
      </div>
  </div>
)

}

MHRDurationHist.propTypes = {
  values: PropTypes.array,
  color: PropTypes.string,
}


export const MHCycleGraph = ({durations, fractions}) => {
  const minimized = window.innerHeight < 900
  
  const lowerFraction = []
  for (let i=0; i < durations.length; i++ ) {
    lowerFraction.push(1 - fractions.raise_fraction[i] - fractions.hold_fraction[i])
  }

  fractions.lower_fraction = lowerFraction
  
  const relativeFractionStats = {}
  for (const fraction in fractions){
    relativeFractionStats[fraction] = {
      mean: average(fractions[fraction]),
      std: stdDev(fractions[fraction])
    }
  }

  const absoluteFractionStats = {}
  for (const fraction in fractions){
    const absFrac = multiplyArrays(fractions[fraction], durations)
    absoluteFractionStats[fraction] = {
      mean: average(absFrac),
      std: stdDev(absFrac)
    }
  }

  const data = {
    datasets: [
      {
        label: 'Raise',
        data: [ relativeFractionStats.raise_fraction.mean * 100],
        borderWidth: 1,
        backgroundColor: [`${colors.lightBlue}60`],
        barPercentage: 1.,
      },
      {
        label: 'Hold',
        data: [ relativeFractionStats.hold_fraction.mean * 100],
        borderWidth: 1,
        backgroundColor: [`${colors.darkBlue}80`],
        barPercentage: 1.,
      },
      {
        label: 'Lower',
        data: [ relativeFractionStats.lower_fraction.mean * 100],
        borderWidth: 1,
        backgroundColor: [`${colors.lightGrey}80`],
        barPercentage: 1.,
      },
    ]
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scales: {
      xAxes: [{
          stacked: true,
          ticks: {
            min: 0,
            max: 100,
            display: false,
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
      }],
      yAxes: [{
        stacked: true,
        ticks: {
          display: false,
        },
        gridLines: {
          display: false,
          drawBorder: false,
        }
      }]
    },
    tooltips: {
      mode: 'nearest',
      enabled: false,
      callbacks: {
        title: function () {
          return '';
        },
        label: function (tooltipItem, chartData) {
          return `${chartData.datasets[tooltipItem.datasetIndex].label}`;
        },
        footer: function (tooltipItem) {
          const parameter = Object.keys(relativeFractionStats)[tooltipItem[0].index]
          const mean = relativeFractionStats[parameter].mean * 100
          const std = relativeFractionStats[parameter].std * 100

          return `${mean.toFixed(2)} ± ${std.toFixed(2)}%`
        }
      }
    } 
  }


  const hrShoeImage = new Image()
  hrShoeImage.src = process.env.PUBLIC_URL + "/simplified-shoe.jpg"
  
  const drawHeelRaise = (ctx) => {
    hrShoeImage.onload = function() {
      drawHeelRaise(ctx)
    }

    if (hrShoeImage.complete) {
      const {offsetWidth, offsetHeight} = ctx.canvas

      const ar = hrShoeImage.width / hrShoeImage.height;  
      
      const relImageSize= 0.8
      const relImageWidth = relImageSize *  offsetWidth;
      const relImageHeight = 1 / ar * relImageWidth;

      ctx.save()
      
      ctx.translate(0.1 * offsetWidth, offsetHeight)
      const endAngle = Math.PI / 4
      const picts = 2

      for (let i=0; i < picts; i++) {
        ctx.globalAlpha = (i < picts - 1) ? 0.2 : 1
        ctx.rotate(-endAngle / picts * i)
        ctx.drawImage(hrShoeImage, 0, -relImageHeight, relImageWidth, relImageHeight)
        ctx.rotate(endAngle / picts * i)
      }
      ctx.globalAlpha = 1

      ctx.restore()
    }
  }

  const hhShoeImage = new Image()
  hhShoeImage.src = process.env.PUBLIC_URL + "/simplified-shoe.jpg"
  
  const drawHeelHold = (ctx) => {  
    hhShoeImage.onload = function() {
      drawHeelHold(ctx)
    }

    if (hhShoeImage.complete) {
      const {offsetWidth, offsetHeight} = ctx.canvas

      const ar = hhShoeImage.width / hhShoeImage.height;  
      
      const relImageSize= 0.8
      const relImageWidth = relImageSize *  offsetWidth;
      const relImageHeight = 1 / ar * relImageWidth;

      ctx.save()

      ctx.translate(0.1 * offsetWidth, offsetHeight)
      ctx.rotate(-Math.PI / 8 )
      ctx.drawImage(hhShoeImage, 0, -relImageHeight, relImageWidth, relImageHeight)
      ctx.rotate(Math.PI / 8 )

      ctx.restore()
    }
  }

  const hlShoeImage = new Image()
  hlShoeImage.src = process.env.PUBLIC_URL + "/simplified-shoe.jpg"
  
  const drawHeelLower = (ctx) => {
    hlShoeImage.onload = function() {
      drawHeelLower(ctx)
    }

    if (hlShoeImage.complete) {
      const {offsetWidth, offsetHeight} = ctx.canvas

      const ar = hlShoeImage.width / hlShoeImage.height;  
      
      const relImageSize= 0.8
      const relImageWidth = relImageSize *  offsetWidth;
      const relImageHeight = 1 / ar * relImageWidth;

      ctx.save()
      
      ctx.translate(0.1 * offsetWidth, offsetHeight)
      const endAngle = Math.PI / 4
      const picts = 2

      for (let i=0; i < picts; i++) {
        ctx.globalAlpha = (i < picts - 1) ? 1. : 0.2
        ctx.rotate(-endAngle / picts * i)
        ctx.drawImage(hlShoeImage, 0, -relImageHeight, relImageWidth, relImageHeight)
        ctx.rotate(endAngle / picts * i)
      }
      ctx.globalAlpha = 1

      ctx.restore()
    }
  }

  const plugins = [addPaddingPlugin()];

  const formatStatString = (parameter, unit) => {
    let coefficient = 1.
    let decimal = 2
    if (unit == "%") {
      coefficient = 100
      decimal = 1
    }

    
    const mean = (parameter.mean * coefficient).toFixed(decimal)
    const std = (parameter.std * coefficient).toFixed(decimal)

    return `${mean} ± ${std}${unit}`
  }

  return (
    <div style={{height: '100%', width: '100%'}}>
      <Grid container sx={{display: 'flex', justifyContent: 'center', alignContent: 'start', height: '100%', width: '100%', mt: 1}}>
        <Grid item xs={4} sx={{display: 'flex', justifyContent: 'center' }}>
          <Typography variant='caption'>
            Raise
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{display: 'flex', justifyContent: 'center' }}>
          <Typography variant='caption'>
            Hold
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{display: 'flex', justifyContent: 'center' }}>
          <Typography variant='caption'>
            Lower
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{height: "20%" }}>
          <DPIAwareCanvas draw={drawHeelRaise} aspectRatio={1.8}/>
        </Grid>
        <Grid item xs={4} sx={{height: "20%" }}>
          <DPIAwareCanvas draw={drawHeelHold} aspectRatio={1.8}/>
        </Grid>
        <Grid item xs={4} sx={{height: "20%"}}>
          <DPIAwareCanvas draw={drawHeelLower} aspectRatio={1.8}/>
        </Grid>
        <Grid item xs={12} sx={{width: '100%', height: '5%'}}>
          <HorizontalBar data={data} options={options} plugins={plugins} height="100%" width="100%"/>
        </Grid>
        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', height: 3, mt: 1}}>
          <Divider orientation='horizontal' flexItem sx={{width: '100%' }}/>
        </Grid>
        <Grid item xs={4} sx={{display: 'flex', justifyContent: 'center' }}>
          <Typography variant='caption' sx={{fontSize: 10}}>
            {formatStatString(relativeFractionStats.raise_fraction, '%')}
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{display: 'flex', justifyContent: 'center' }}>
          <Typography variant='caption' sx={{fontSize: 10}}>
            {formatStatString(relativeFractionStats.hold_fraction, '%')}
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{display: 'flex', justifyContent: 'center' }}>
          <Typography variant='caption' sx={{fontSize: 10}}>
            {formatStatString(relativeFractionStats.lower_fraction, '%')}
          </Typography>
        </Grid>
        {!minimized &&
          <Fragment>
            <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', height: 3, mt: 1 }}>
              <Divider orientation='horizontal' flexItem sx={{width: '100%' }}/>
            </Grid>
            <Grid item xs={4} sx={{display: 'flex', justifyContent: 'center' }}>
              <Typography variant='caption' sx={{fontSize: 10}}>
                {formatStatString(absoluteFractionStats.raise_fraction, 's')}
              </Typography>
            </Grid>
            <Grid item xs={4} sx={{display: 'flex', justifyContent: 'center' }}>
              <Typography variant='caption' sx={{fontSize: 10}}>
                {formatStatString(absoluteFractionStats.hold_fraction, 's')}
              </Typography>
            </Grid>
            <Grid item xs={4} sx={{display: 'flex', justifyContent: 'center' }}>
              <Typography variant='caption' sx={{fontSize: 10}}>
                {formatStatString(absoluteFractionStats.lower_fraction, 's')}
              </Typography>
            </Grid>
          </Fragment>
        }
      </Grid>
    </div>
  )
}

MHCycleGraph.propTypes = {
  durations: PropTypes.array,
  fractions: PropTypes.object,
}
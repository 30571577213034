import { Line } from "react-chartjs-2";
import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

export const MAdvancedStatsResults = ({ results }) => {
  const theme = useTheme()
  const color = theme.palette.info.main
  
  const ts = results.fi.t.map(_ts => _ts - results.fi.t[0])
  const freezeIndex = results.fi.values
  const chartData = {
    labels: ts,
    datasets: [
      {
        label: "Freeze Index",
        data: freezeIndex.map((fi, idx) => ({x: ts[idx], y: fi})),
        fill: true,
        pointRadius: 0,
        borderWidth: 1,
        backgroundColor: `${color}60`,
        borderColor: color,

      }
    ]
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: `Time [s]`
        },
        ticks: {
					display: true,
          min: 0,
          max: Math.floor(ts[ts.length - 1]),
          callback: (val) => {
            return Math.floor(val)
          }
					
				},
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: `FI`,
        },
				ticks: {
					min: 0,
					max: 8,
					display: true,
					stepSize: 2,
				},
        gridLines: {
          display: true,
        },
      }]
    },
  }

  return (
    <Box sx={{display: "flex", width: "100%"}}>
      <Line data={chartData} options={chartOptions}/>
    </Box>
  )
}

MAdvancedStatsResults.propTypes = {
  results: PropTypes.object,
};
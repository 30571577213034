import React, { Fragment } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Box, Divider, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PropTypes } from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { MStatisticsCard } from '../../../components/ui/MStatisticsCard';
import { getReferenceRange } from '../../../definitions/gaitParameterRefRanges.js';
import { colors } from '../../../styles/colors.js';
import { loadPatientData } from '../../../utils/auxFunctions';

import { MParameterChartPanel } from './MParameterChartPanel.js';
import { MVariabilityDialog } from './MVariabilityDialog.js';

const TITLE_TO_KEY = {
  Mean: 'avg',
  STD: 'std',
  CoV: 'varIDX'
};

/**
 * Card with assessment's analysed gait parameters
 * @return {jsx} The MgaitparameterCard
 */
export const MGaitParameterCard = ({ parameter, statistics, variabilityCoefficients }) => {
  const theme = useTheme();

  const { sex, age } = loadPatientData()
  const { id, icon, title, unit } = parameter;

  let cardColor;
  const deltaSymmetry = Math.abs(statistics['L'].symIDX - 50);
  if (deltaSymmetry <= 5) {
    cardColor = theme.palette.success.main;
  } else if (5 < deltaSymmetry && deltaSymmetry <= 10) {
    // Leave card Color undefined
  } else if (10 < deltaSymmetry && deltaSymmetry <= 15) {
    cardColor = theme.palette.warning.main;
  } else {
    cardColor = theme.palette.error.main;
  }

  const referenceRange = getReferenceRange(id, sex, age);
  const ReferenceLabel = (prop) => {
    if (!referenceRange || !['Mean', 'STD'].includes(prop.type)) {
      return (
        <Tooltip title={'No Reference Available'}>
          <Box sx={{ height: 25 }} />
        </Tooltip>
      );
    } else {
      const [lb, ub, stdRef] = referenceRange;
      const txt = prop.type === 'Mean' ? `${lb.toFixed(1)} - ${ub.toFixed(1)}` : stdRef.toFixed(1);
      return (
        <Tooltip title={'ReferenceRange'}>
          <Box sx={{ display: 'flex', width: '100%', height: 25, justifyContent: 'center' }}>
            <Typography
              variant="caption"
              sx={{
                fontSize: 12,
                color: colors['lightGrey']
              }}
            >
              <big>—[</big>
              <Typography
                align="center"
                variant="caption"
                sx={{
                  fontSize: 12,
                  color: 'black'
                }}
              >
                {txt}
              </Typography>
              <big>]—</big>
            </Typography>
          </Box>
        </Tooltip>
      );
    }
  };

  const plots = [];
  for (const [title, key] of Object.entries(TITLE_TO_KEY)) {
    plots.push({
      title: title,
      chartData: {
        labels: ['Left', 'Right'],
        datasets: [
          {
            data: [Number(statistics.L[key].toFixed(2)), Number(statistics.R[key].toFixed(2))],
            backgroundColor: [theme.palette.info.main, theme.palette.secondary.main],
            borderWidth: 3,
            barPercentage: 0.5
          }
        ]
      },
      unit: unit
    });
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    rotation: Math.PI,
    circumference: Math.PI,
    layout: {
      padding: {
        bottom: 5
      }
    },
    legend: false,
    tooltips: {
      callbacks: {
        label: function (tooltipItem, chartData) {
          const dataset = chartData['datasets'][0];
          const side = chartData['labels'][tooltipItem['index']];
          const value = dataset['data'][tooltipItem['index']];
          return `${side} ${value} ${unit}`;
        }
      }
    }
  };
  return (
    <Fragment>
      <MStatisticsCard title={<big>{title}</big>} icon={icon} color={cardColor}>
        <Stack
          divider={<Divider orientation="horizontal" flexItem />}
          spacing={1}
          sx={{ height: '100%', marginTop: -1 }}
        >
          <Grid
            container
            key={uuidv4()}
            sx={{ align: 'center', justifyContent: 'center', height: '40%', width: '100%', p: 1 }}
          >
            {plots.map((plt) => {
              const leftValue = plt.chartData.datasets[0].data[0];
              const rightValue = plt.chartData.datasets[0].data[1];
              const itemUnit = plt.title === "CoV" ? "" : unit;
              
              return (
                <Grid
                  container
                  item
                  xs={4}
                  key={uuidv4()}
                  sx={{ height: '100%', width: '100%', position: 'relative' }}
                >
                  <Grid item xs={12}>
                    <Typography align="center" variant="overline" sx={{ fontSize: 9 }}>
                      {plt.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ minHeight: 35, height: 'calc( 0.40 * (50vh - 80px) - 120px)' }}>
                    <Doughnut data={plt.chartData} options={chartOptions}/>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={6}>
                      <Typography align="center" variant="body2">
                        {leftValue}
                        <small>{itemUnit}</small>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography align="center" variant="body2">
                        {rightValue}
                        <small>{itemUnit}</small>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <ReferenceLabel type={plt.title} />
                    </Grid>
                  </Grid>
                  {plt.title === 'CoV' && (
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: '20%',
                        right: '50%',
                        transform: 'translate(50%, -50%)',
                        zIndex: 10
                      }}
                    >
                      <MVariabilityDialog variabilityCoefficients={variabilityCoefficients} />
                    </Box>
                  )}
                </Grid>
              );
            })}
          </Grid>
          <Box sx={{ height: '60%', width: '100%'}}>
            <MParameterChartPanel parameter={parameter} statistics={statistics} />
          </Box>
        </Stack>
      </MStatisticsCard>
    </Fragment>
  );
};

MGaitParameterCard.propTypes = {
  parameter: PropTypes.object,
  statistics: PropTypes.object,
  variabilityCoefficients: PropTypes.array
};
